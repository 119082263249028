<template>
	<v-dialog v-model="editMode" persistent max-width="500">
		<template #activator="{ on }">
			<div class="text-center" style="width: 100%">
				<v-btn text small rounded color="warning" v-on="on">
					Add more sectors
				</v-btn>
			</div>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">Add more sectors</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-row class="mb-2">
					<v-col>
						<v-chip
							:ripple="false"
							v-for="sector in newSectors"
							:key="`sector${sector.id}`"
							class="mx-1 my-1"
							color="green darken-1"
							close
							@click:close="callRemoveSector(sector)"
						>
							{{ sector.name }}
						</v-chip>
					</v-col>
				</v-row>
				<v-autocomplete
					v-model="selectedSector"
					:items="selectableSectors"
					:loading="loading || loadingParent"
					:search-input.sync="search"
					:filter="customFilter"
					hide-details="auto"
					hide-selected
					item-text="name"
					item-value="id"
					:label="`${$t('search.search')}...`"
					return-object
					outlined
					rounded
					class="pb-3"
				>
					<template #no-data>
						<v-list-item v-if="search == null">
							<v-list-item-title>
								<strong>{{ $t('search.search') }}</strong>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-else-if="adquiredSector(search)">
							<v-list-item-title>{{ $t('cv.alreadyAdded') }}</v-list-item-title>
						</v-list-item>
						<v-list-item v-else>
							<v-list-item-title>
								{{ $t('search.noResults') }}
							</v-list-item-title>
						</v-list-item>
					</template>
					<template #item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.name" @click="callAddSector(item)" />
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'CompanySectorsAdd',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			search: null,
			selectedSector: null,
			newSectors: []
		}
	},
	computed: {
		selectableSectors() {
			return this.availableSectors.filter((sector) => !this.company.info.sectors.map((s) => s.id).includes(sector.id))
		},
		...mapGetters({
			company: 'companies/company',
			availableSectors: 'companies/availableSectors'
		})
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableSectors(val)
		}
	},
	created() {
		this.queryAvailableSectors('')
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		queryAvailableSectors(search) {
			this.loading = true
			this.fetchAvailableSectors({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		adquiredSector(search) {
			return this.company.info.sectors.map((sector) => sector.name).includes(search)
		},
		callAddSector(sector) {
			this.loading = true
			this.addSector({
				sectorID: sector.id
			})
				.then(({ success, sector }) => {
					if (success) {
						this.newSectors.push(sector)
						this.selectedSector = null
						this.search = ''
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		callRemoveSector(sector) {
			this.loading = true
			this.removeSector({
				sectorID: sector.id
			}).then(() => {
				this.loading = false
				this.newSectors.splice(sector, 1)
			})
		},
		...mapActions('companies', ['fetchAvailableSectors', 'addSector', 'removeSector'])
	}
}
</script>
